import React, { useEffect, useState, useContext } from "react";
import { Button, Container, Form, Modal, ProgressBar } from "react-bootstrap";
import "react-phone-number-input/style.css";
import es from "react-phone-number-input/locale/es.json";
import PhoneInput from "react-phone-number-input";
import { softphoneContext } from "../../context/softphoneContext";
import Select from "react-select";
import Crud_Catalogos from "../../herramientas/Crud_Catalogos";

const TransferModal = ({ show, handleCloseModal, handleOKButton }) => {
  //const [currentRadio, setCurrentRadio] = useState();
  //const [countryCode, setCountryCode] = useState(52);
  //const [numeroTelefonico, setNumeroTelefonico] = useState();
  const transfer_call_sound = new Audio("songs/transfer_call.wav");
  const softphone = useContext(softphoneContext);
  const grupo = softphone.getValues().grupo;
  const [usuariosFormateados, setUsuariosFormateados] = useState();
  const [numeroALlamar, setNumeroALlamar] = useState();

  //Use Effect para estilos del input de banderas
  useEffect(() => {
    // if (show) {
    //   document.getElementsByClassName("PhoneInputInput")[1].disabled = true;
    // }
    getfuntion(
      "get",
      "",
      "",
      `softphone/grupo_contactos/${grupo}`,
      "grupo_contactos"
    );
  }, [show]);

  const getfuntion = (
    metodo = "get",
    obj = [],
    id = "",
    catalogo = "",
    stateVar = "",
    hiddenModl = ""
  ) => {
    return Crud_Catalogos(
      catalogo,
      "usuario",
      metodo,
      id,
      obj,
      "",
      stateVar,
      hiddenModl,
      []
    )
      .then((returnVal) => {
        /**
         * filtro de variables
         */
        switch (metodo) {
          case "get":
            setUsuariosFormateados(
              returnVal.map((usuario) => {
                return {
                  id: usuario.id,
                  value: usuario.username,
                  label: `${usuario.persona.nombre} ${
                    usuario.persona.aPaterno
                  } ${usuario.persona.aMaterno ?? ""} / ${usuario.extension}`,
                  extension: usuario.extension,
                  nombre: `${usuario.persona.nombre} ${
                    usuario.persona.aPaterno
                  } ${usuario.persona.aMaterno ?? ""}`,
                };
              })
            );
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkNumberInput = () => {
    handleOKButton(numeroALlamar.extension);
    transfer_call_sound.play();
  };

  if (!usuariosFormateados) {
    return <ProgressBar animated />;
  }

  return (
    <Modal show={show} onHide={handleCloseModal}>
      <Modal.Header>
        <Modal.Title>Transferir llamada</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Seleccionar el contacto a quien desea transferir la llamada.
      </Modal.Body>
      <Container>
        <Form>
          <Select
            placeholder="Seleccionar"
            value={numeroALlamar}
            onChange={setNumeroALlamar}
            options={usuariosFormateados}
          />
        </Form>
      </Container>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Cerrar
        </Button>
        <Button
          variant="primary"
          onClick={checkNumberInput}
          disabled={!numeroALlamar}
        >
          Transferir
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TransferModal;
